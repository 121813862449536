import { getYTID } from "../../../components/tools/index.js"
import { Card } from "react-bootstrap"
import React from "react"

export function VideoCard({ title, url }) {
    if (!title || !url) return null
    return (
        <Card
            style={{ width: "15rem" }}
            className="video-card"
            data-testid="card"
        >
            <Card.Img
                data-testid="image"
                variant="top"
                src={`https://img.youtube.com/vi/${getYTID(url)}/0.jpg`}
                style={{
                    width: "238px",
                    height: "181px",
                    background: "black",
                }}
            />
            <Card.Body className="p-2">
                <Card.Title data-testid="title">{title}</Card.Title>
                <Card.Subtitle style={{ color: "#9e9e9e" }}>
                    EGI - Energy & Geoscience Institute
                </Card.Subtitle>
            </Card.Body>
        </Card>
    )
}
