import React from "react"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import rehypeRaw from "rehype-raw"

export function Markdown({ children, className }) {
    return (
        <ReactMarkdown
            remarkPlugins={[gfm]}
            rehypePlugins={[rehypeRaw]}
            className={className}
        >
            {children}
        </ReactMarkdown>
    )
}
