import React from "react"
import { Link } from "gatsby"
import { Button, Card } from "react-bootstrap"

export function CoreCard({
    title,
    subtitle,
    children,
    button = "Learn More",
    href,
}) {
    if (!title || !children) return null
    return (
        <Card
            style={{ width: "18rem", minWidth: "300px" }}
            className="m-4"
            data-testid="core-card"
        >
            <Card.Body>
                <h6
                    className="mb-2 text-muted text-uppercase"
                    style={{ fontSize: "12px" }}
                    data-testid="subtitle"
                >
                    {subtitle}
                </h6>
                <Card.Title className="border-bottom pb-3" data-testid="title">
                    {title}
                </Card.Title>
                <Card.Text data-testid="children">{children}</Card.Text>
                {href.includes("http") ? (
                    <a data-testid="anchor" href={href}>
                        <Button variant="egi">{button}</Button>
                    </a>
                ) : (
                    <Link to={href} data-testid="link">
                        <Button variant="egi">{button}</Button>
                    </Link>
                )}
            </Card.Body>
        </Card>
    )
}
