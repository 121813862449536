import React from "react"
import PropTypes from "prop-types"

export function Path({ idx = 5, height }) {
    if (!height) return null

    const h = 50 * (idx + 1) - 50 / 2
    const t = [100, 25] // target xVal, yVal
    const c = 0 //curve
    const sW = 100 //svgWidth
    return (
        <svg viewBox={`0 0 ${sW} ${height}`} data-test="path-component">
            <path
                d={`M${c},${h} C${sW},${h} ${c},${t[1]} ${t[0]},${t[1]}`}
                style={{
                    fill: "none",
                    stroke: "var(--red1)",
                    strokeWidth: "2px",
                }}
            />
            <circle
                cx="0"
                cy={h}
                r="5"
                style={{
                    fill: "#fff",
                    stroke: "var(--red1)",
                    strokeWidth: "2px",
                }}
            />
            <circle
                cx={t[0]}
                cy={t[1]}
                r="5"
                style={{
                    fill: "#fff",
                    stroke: "var(--red1)",
                    strokeWidth: "2px",
                }}
            />
        </svg>
    )
}

Path.propTypes = {
    idx: PropTypes.number,
    height: PropTypes.number,
}
