import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

export function Footer() {
    const date = new Date()
    return (
        <div data-testid="footer">
            <Container className="bg-dark mw-100 text-light">
                <Container className="p-3">
                    <Row className="p-3">
                        <Col style={{ maxWidth: "250px" }}>
                            <Row>
                                <a href="https://utah.edu/" target="_blank">
                                    <img
                                        src="/media/U_Logo.png"
                                        alt="UofU Logo"
                                    />
                                </a>
                            </Row>
                            <hr className="border-light" />
                            <Row className="pl-2">
                                <p>
                                    Energy &amp; Geoscience Institute
                                    <br />
                                    423 Wakara Way, Suite 300
                                    <br />
                                    Salt Lake City, UT 84108
                                    <br />
                                    801-581-5126
                                </p>
                            </Row>
                        </Col>
                        <Col style={{ maxWidth: "250px" }}>
                            <Row className="p-4">
                                <a href="https://egigis.maps.arcgis.com/apps/webappviewer/index.html?id=be9b9cc5d7ec4ad489520d71c5c72209" target="_blank">
                                    <img
                                        src="/media/EGIconnect_Banner.png"
                                        alt="EGIconnect Banner"
                                        width="100%"
                                    />
                                </a>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="p-3">
                                <a
                                    href="https://www.che.utah.edu/" target="_blank"
                                    className="m-2"
                                >
                                    <img
                                        src="/media/dep_chem_eng_uofu.png"
                                        width={250}
                                        alt="dep_chem_eng_uofu"
                                    />
                                </a>
                                <a
                                    href="https://www.civil.utah.edu/" target="_blank"
                                    className="m-2"
                                >
                                    <img
                                        src="/media/dep_civi_eng_uofu.png"
                                        width={250}
                                        alt="dep_chem_eng_uofu"
                                    />
                                </a>
                                <a
                                    href="https://earth.utah.edu/" target="_blank"
                                    className="m-2"
                                >
                                    <img
                                        src="/media/dep_geol_geo_uofu.png"
                                        width={250}
                                        alt="dep_chem_eng_uofu"
                                    />
                                </a>
                                <a
                                    href="https://www.mech.utah.edu/" target="_blank"
                                    className="m-2"
                                >
                                    <img
                                        src="/media/dep_mech_eng_uofu.png"
                                        width={250}
                                        alt="dep_chem_eng_uofu"
                                    />
                                </a>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container
                className="mw-100 "
                style={{
                    background: "var(--dark1)",
                    fontWeight: "300",
                    fontSize: "16px",
                    color: "#ffffffd1",
                }}
            >
                <Container className="p-4">
                    <Row className="text-center pb-2">
                        <Col>
                            © <span>{date.getFullYear()}</span>{" "}
                            <Link
                                to="/"
                                style={{
                                    color: "#ffffffd1",
                                }}
                            >
                                Energy & Geoscience Institute
                            </Link>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <a
                                href="https://www.utah.edu/nondiscrimination/"
                                className="px-2"
                                style={{
                                    color: "#ffffffd1",
                                }}
                            >
                                Nondiscrimination & Accessibility Statement
                            </a>
                            /
                            <a
                                href="https://www.utah.edu/disclaimer/"
                                className="px-2"
                                style={{
                                    color: "#ffffffd1",
                                }}
                            >
                                Disclaimer
                            </a>
                            /
                            <a
                                href="https://www.utah.edu/privacy/"
                                className="px-2"
                                style={{
                                    color: "#ffffffd1",
                                }}
                            >
                                Privacy
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}
