function toUrl(str) {
    if (!str) return null
    return str
        .replace(/,|&|:|'/g, "")
        .replace(/ +/g, "-")
        .toLowerCase()
}

function getYTID(url) {
    if (!url) return null
    var ID = ""
    url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if (url[2] !== undefined) {
        // eslint-disable-next-line no-useless-escape
        ID = url[2].split(/[^0-9a-z_\-]/i)
        ID = ID[0]
    } else {
        ID = url
    }
    return ID
}

function toTitleCase(str) {
    if (!str) return null
    return str.replace(/-|_/g, " ").replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

module.exports = {
    toUrl,
    getYTID,
    toTitleCase,
}
