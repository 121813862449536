import React from "react"
import { Form } from "react-bootstrap"
import { ValidationError } from "@formspree/react"

export function Input({
    title,
    name,
    type,
    value,
    required = true,
    hidden,
    as,
    rows,
    errors,
}) {
    return (
        <Form.Group controlId={"control_id_" + name} hidden={hidden}>
            <Form.Label>{title + ":"}</Form.Label>
            <Form.Control
                type={type}
                name={name}
                value={value}
                as={as}
                rows={rows}
                readOnly={value ? true : false}
                required={required}
            />
            <ValidationError prefix={title} field={name} errors={errors} />
        </Form.Group>
    )
}
