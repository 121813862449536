import React from "react"

export function Fallback({
    width = "100px",
    height = "100px",
    style = "",
    message = "Check dev tools for info.",
}) {
    return (
        <div style={style} data-testid="style-wrapper">
            <div
                style={{
                    background: "rgb(248, 249, 250)",
                    border: "1px solid rgba(0,0,0,.125)",
                    height: height,
                    width: width,
                    color: "#c8c8c8",
                    padding: "5px 10px",
                }}
                data-testid="message"
            >
                {message}
            </div>
        </div>
    )
}
