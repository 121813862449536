import React from "react"
import { Container } from "react-bootstrap"

export function PageCenter({
    children,
    bg = "white",
    text = "dark",
    className = "mw-100 p-5",
}) {
    if (!children) return null

    return (
        <Container className={`text-${text} ${className} bg-${bg}`}>
            <Container data-testid="children">{children}</Container>
        </Container>
    )
}
