import React from "react"
import { Helmet } from "react-helmet"

export function MetaTags({
    title = "Leading The Energy Transition",
    description = "EGI’s mission is to create innovative science and technology to explore for and produce Earth’s energy and mineral resources sustainably.",
    keywords = "power, renewable energy, geothermal energy, energy fluids, carbon management, geothermal research",
}) {
    return (
        <Helmet>
            <title>{`${title} | Energy & Geoscience Institute`}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    )
}
