import React from "react"
import { Helmet } from "react-helmet"

export function GA({ trackingID }) {
    if (!trackingID) return null
    return (
        <Helmet data-testid="styles">
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${trackingID}`}
            ></script>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments)}
                    gtag('js', new Date());
                    gtag('config', '${trackingID}');
                    

                `}

            </script>
        </Helmet>
    )
}
