import React from "react"

import { Header } from "./Header.jsx"
import { Footer } from "./Footer.jsx"
import { Styles } from "./styles"
import { GA } from "./scripts"
import { Breadcrumbs } from "./Breadcrumbs.jsx"
import { useStaticQuery, graphql } from "gatsby"
import { MetaTags } from "./helmet"
import { Notification } from "./notification/Notification.jsx"
import { NotificationWrapper } from "./wrappers"
import { Markdown } from "../tools/Markdown.js"

function winDef() {
    return typeof window !== "undefined"
}

export function Page({ children, title, description, crumbs = true }) {
    if (!children) return null
    const {
        q1: {
            frontmatter: { components: c },
        },
        q2: { edges: notifications },
    } = useStaticQuery(graphql`
        {
            q1: markdownRemark(frontmatter: { id: { eq: "elem-02" } }) {
                ...ElementFields
            }
            q2: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "//notifications//" } }
            ) {
                edges {
                    node {
                        ...NotificationFields
                    }
                }
            }
        }
    `)

    let nElements
    if (winDef())
        nElements = notifications.map(
            (
                {
                    node: {
                        html,
                        frontmatter: { slug, header, delay, active },
                    },
                },
                i,
            ) => {
                if (window.location.pathname === slug)
                    return (
                        <Notification
                            key={i}
                            active={active}
                            header={header}
                            delay={delay}
                        >
                            <Markdown>{html}</Markdown>
                        </Notification>
                    )
            },
        )

    return (
        <>
            <Styles />
            <GA trackingID="UA-52056852-1" />
            <MetaTags title={title} description={description} />
            <Header menu={c[0].items} />
            <div style={{ paddingTop: "70px" }}>
                {winDef() && crumbs ? (
                    <Breadcrumbs title={title} loc={window.location.pathname} />
                ) : null}
                {children}
            </div>
            <NotificationWrapper>{nElements}</NotificationWrapper>
            <Footer />
        </>
    )
}
