import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "@formspree/react";
import { Input } from "./Input";

export function FormX({
  inputs = [],
  reason,
  fsID,
  callback,
  button = "Submit",
  thank_you = "Thank you!",
  request_message = "EGIconnect Support will contact you with a secure link and download credentials to access the requested EGI library item. Thank you!"
}) {
  if (!inputs || !fsID || !reason) return null;

  const [state, handleSubmit] = useForm(fsID);

  if (state.succeeded) {
    if (callback) callback();
    if (fsID == "myyodjab") {return <p>{request_message}</p>}
    return <p>{thank_you}</p>
    
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input title="Reason" name="Reason" type="text" value={reason} hidden />
      {inputs.map((e, i) => {
        const name = e.title.replace(/\s\s+/g, "_");
        return (
          <Input
            key={i}
            title={e.title}
            name={name}
            type={e.type}
            errors={state.errors}
            value={e.value}
            as={e.as}
            rows={e.rows}
            readOnly={e.readOnly}
          />
        );
      })}

      <Button variant="egi" type="submit" disabled={state.submitting}>
        {button}
      </Button>
    </Form>
  );
}