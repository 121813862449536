import React from "react"
import { Fallback } from "./Fallback.jsx"

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    // eslint-disable-next-line no-unused-vars
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.log(error, errorInfo)
        //   logErrorToMyService(error, errorInfo);
    }

    render() {
        if (!this.props.children) return null
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback ? (
                <Fallback {...this.props.fallback} />
            ) : (
                <Fallback width="200px" height="100px" />
            )
        }

        return this.props.children
    }
}
