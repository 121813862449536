import React from "react"
import { getImage } from "gatsby-plugin-image"
import { Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { MDForm } from "../../forms"
import { Markdown } from "../../tools/Markdown"
import { PortfolioCard } from "../cards"
import { Tabs, Slider } from ".."

function Component({ type, tab_type, ...props }) {
    if (!type) return null
    switch (type) {
        case "css":
            return (
                <Helmet>
                    <style type="text/css">{`${props.custom_code.code}`}</style>
                </Helmet>
            )

        case "javascript":
            return (
                <Helmet>
                    <script>{`${props.custom_code.code}`}</script>
                </Helmet>
            )

        case "markdown":
            return (
                <Row className={props.className}>
                    <Markdown>{props.html}</Markdown>
                </Row>
            )
        case "card":
            return (
                <Row className={props.className}>
                    <PortfolioCard
                        title={props.title}
                        image={props.image}
                        button={props.button}
                        href={props.href}
                    />
                </Row>
            )
        case "form":
            return (
                <Row className={props.className}>
                    <MDForm formdata={props} />
                </Row>
            )
        case "tabs":
            return (
                <Row className={props.className}>
                    <Tabs data={props.tab} type={tab_type} />
                </Row>
            )
        case "carousel":
            var images = props.slides.map(({ image }) => {
                return {
                    image: getImage(image),
                }
            })
            return (
                <Row className={props.className}>
                    <Slider
                        items={images}
                        className="mb-5"
                        style={{
                            width: "100%",
                            maxHeight: "400px",
                            overflow: "hidden",
                        }}
                    />
                </Row>
            )

        default:
            return null
    }
}

export default function Components({ components, ...props }) {
    if (!components) return null
    return components.map((e, i) => (
        <Component
            key={i}
            {...e}
            className={props.className}
            tab_type={props.tab_type}
        />
    ))
}
