import React from "react"
import { Link } from "gatsby"
import { toUrl } from "../../tools"

export function PICard({ member: { category, slug, fullName, info } }) {
    return (
        <div className="m-2">
            {category !== "Unset" ? (
                <Link to={`/about/${toUrl(category)}/${slug}`} className="m-0">
                    {fullName}
                </Link>
            ) : (
                <p className="m-0">{fullName}</p>
            )}
            {info?.map(({ label, value }, i) =>
                label.toLowerCase() != "fax" ? (
                    <p className="m-0" key={i}>
                        {value}
                    </p>
                ) : null,
            )}
        </div>
    )
}
