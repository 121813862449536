import React from "react"
import { Helmet } from "react-helmet"

export function Styles() {
    return (
        <Helmet data-testid="styles">
            <style type="text/css">
                {`
                        :root {
                            --red1: #cc1c2b;
                            --red2: #bd2130;
                            --shadow: #cacaca9e;
                            --dark1: #272c30;
                        }
                        
                        a {
                            color: var(--red1);
                        }
                        
                        a:hover {
                            text-decoration: underline;
                        
                            color: var(--red2);
                        }
                        
                        [data-test="main"] {
                            padding-top: 77px;
                        }
                        
                        .btn-egi {
                            color: #fff;
                            border-color: var(--red1);
                            background-color: var(--red1);
                        }
                        
                        .btn-egi.focus,
                        .btn-egi:focus {
                            color: #fff;
                            border-color: var(--red1);
                            background-color: var(--red1);
                            box-shadow: 0 0 0 0.2rem rgb(225 83 97 / 50%);
                        }
                        
                        .btn-egi:hover {
                            color: #fff;
                            border-color: var(--red2);
                            background-color: var(--red2);
                        }
                        
                        .badge-egi {
                            color: #fff;
                            background-color: var(--red1);
                        }
                        
                        .bg-red-1 {
                            background-color: var(--red1);
                        }
                        
                        .dropdown-item.active,
                        .dropdown-item:active {
                            text-decoration: none;
                        
                            color: #fff;
                            background-color: var(--red1);
                        }
                        
                        .breadcrumb {
                            margin-bottom: 0;
                        
                            background-color: #f8f8f8;
                        }
                        
                        .text-egi {
                            color: var(--red1) !important;
                        }
                        
                        span {
                            display: inline-block;
                        }
                        
                        span.description {
                            margin-bottom: 10px;
                        }
                        
                        .list-group-item {
                            cursor: pointer;
                        }
                        
                        .list-group-item.active {
                            background-color: var(--red1);
                            border-color: var(--red2);
                        }

                        .form-control:focus {
                            border-color: var(--red1);
                            box-shadow: 0 0 0 0.2rem rgb(225 83 97 / 50%);
                        }

                        .navbar-light .navbar-nav .nav-link {
                            color: rgb(0 0 0 / 80%);
                        }

                        blockquote p{
                            border-left: 3px solid var(--red1);
                            margin-left: 10px;
                            padding-left: 10px;
                            font-style: italic;
                        }
                        
                        @media only screen and (max-width: 600px) {
                            .navbar-brand .gatsby-image-wrapper {
                                width: 205px;
                            }
                            .basic-navbar-nav {
                                transform: scale(0.8);
                            }
                            .col {
                                padding: 0 !important;
                                min-width: 250px;
                                margin: 10px;
                            }
                            .row {
                                justify-content: center;
                                flex-wrap: wrap !important;
                            }
                            .p-3 {
                                padding: 8px;
                                line-height: 20px;
                            }
                            .display-3 {
                                font-size: 3.5rem;
                                line-height: 3.5rem;
                            }
                            .p-5 {
                                padding: 1rem !important;
                            }
                            .py-5 {
                                padding-top: 10px !important;
                                padding-bottom: 10px !important;
                            }
                            .list-group-horizontal {
                                flex-wrap: wrap;
                            }
                        }
                    `}
            </style>
        </Helmet>
    )
}
