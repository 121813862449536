import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card } from "react-bootstrap"

export const PostLink = ({ post }) => {
    if (!post) return null
    return (
        <Card
            style={{ width: "17rem", height: "fit-content" }}
            className="m-3 p-2 bg-light masonry-brick"
            data-testid="test-masonry-brick"
        >
            <GatsbyImage
                image={getImage(post.frontmatter.featuredImage)}
                alt={post.frontmatter.title}
            />
            <Card.Body>
                <Link
                    to={"/" + post.frontmatter.slug}
                    data-testid="test-brick-link"
                >
                    <Card.Title>{post.frontmatter.title}</Card.Title>
                </Link>
                <p className="border-bottom pb-2">
                    By {post.frontmatter.author}
                </p>
                <Card.Text>
                    {post.excerpt}
                    <br />({post.frontmatter.date})
                </Card.Text>
                <Link
                    to={"/" + post.frontmatter.slug}
                    className="text-dark"
                    style={{ textDecoration: "none" }}
                >
                    Read More ➜
                </Link>
            </Card.Body>
        </Card>
    )
}
