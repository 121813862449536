import React from "react"

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            x="0"
            y="0"
            fill="var(--red1)"
            viewBox="0 0 100 100"
            style={{ marginRight: "10px" }}
            data-testid="svg"
        >
            <path d="M16.5 16.5C-2 35-2 65 16.5 83.5s48.5 18.5 67 0 18.5-48.5 0-67-48.5-18.5-67 0zm35.4 6c4 0 7.2 3.3 7.2 7.3s-3.3 7.3-7.2 7.3c-4 0-7.3-3.3-7.3-7.4 0-4 3.3-7.2 7.3-7.2zm10.3 48.9c-.2.6-.6 1.3-1 1.7-2.6 2.7-5.8 4.3-9.6 4.3-1.8 0-3.5 0-5.3-.3-2.9-.4-6.6-4-6.1-7.8l1.2-7.8c.8-4.5 1.6-9.1 2.4-13.6 0-.3.1-.6.1-.9 0-1.9-.6-2.6-2.5-2.8-.8-.1-1.6-.2-2.4-.4-.9-.3-1.4-1.1-1.3-1.8.1-.8.6-1.3 1.6-1.5.5-.1 1.1-.1 1.7-.1h13.8c1.7 0 2.7.8 2.7 2.5 0 1.4-.2 2.8-.5 4.2-.9 5.2-1.9 10.3-2.8 15.5-.3 1.7-.7 3.4-.9 5.1-.1.8 0 1.7.2 2.5.3 1.1 1.1 1.7 2.2 1.6.9-.1 1.8-.4 2.7-.8.7-.3 1.3-.8 2-1 1.2-.4 2.1.3 1.8 1.4z"></path>
        </svg>
    )
}

export default Icon
