import React from "react"

export function YTVideo({ src, title }) {
    if (!src) return null
    return (
        <iframe
            title={title}
            style={{
                width: "50vw",
                height: "30vw",
                minWidth: "300px",
                minHeight: "200px",
                maxWidth: "634px",
                maxHeight: "357px",
            }}
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="none"
            className="mb-5"
        ></iframe>
    )
}
