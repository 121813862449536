import React from "react"

export function DefaultHeader({ title, subtitle, type = "default" }) {
    if (!title) return null
    return (
        <div
            className="border-bottom text-center mb-5 p-3"
            data-test="default-header"
            style={{ whiteSpace: "pre-line" }}
        >
            {type === "default" ? (
                <h2 data-testid="title">{title}</h2>
            ) : (
                <h3 data-testid="title">{title}</h3>
            )}
            <p
                className="lead ml-auto mr-auto"
                style={{
                    maxWidth: "800px",
                    fontSize: type === "default" ? "21px" : "17px",
                    whiteSpace: "pre-line",
                }}
                data-testid="subtitle"
            >
                {subtitle}
            </p>
        </div>
    )
}
