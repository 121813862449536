import { Link } from "gatsby"
import ErrorBoundary from "../errors/ErrorBoundary"
import React from "react"
import { Breadcrumb } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { toTitleCase } from "../tools"
import { PageCenter } from "./wrappers"

export function Component({ title, loc }) {
    if (!loc) return null
    let crumbs = loc.split("/").filter(e => e != "")
    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`
                        .breadcrumb-item+.breadcrumb-item::before {
                            content: "▸";
                        }
                        .breadcrumb-item.active {
                            color: #4d4d4d;
                        }
                    `}
                </style>
            </Helmet>
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                {crumbs.map((e, i) => {
                    const end = i == crumbs.length - 1
                    return (
                        <li
                            key={i}
                            className={`breadcrumb-item${end ? " active" : ""}`}
                            data-testid="test-list-item"
                        >
                            {end ? (
                                end && title ? (
                                    title
                                ) : (
                                    toTitleCase(e)
                                )
                            ) : (
                                <Link
                                    to={loc.split(e)[0] + e}
                                    data-testid="test-link"
                                >
                                    {toTitleCase(e)}
                                </Link>
                            )}
                        </li>
                    )
                })}
            </Breadcrumb>
        </>
    )
}

export function Breadcrumbs(props) {
    return (
        <PageCenter className="mw-100 pt-5">
            <ErrorBoundary
                fallback={{
                    width: "100%",
                    height: "50px",
                }}
            >
                <Component {...props} />
            </ErrorBoundary>
        </PageCenter>
    )
}
