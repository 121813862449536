import React from "react";
import { FormX } from "./FormX";

export function GeneralContactForm() {
    return (
        <FormX
            inputs={[
                { title: "Full Name", type: "text" },
                { title: "Company Email Address", type: "text" },
                { title: "Subject", type: "text" },
                { title: "Message", as: "textarea", rows: 3 },
            ]}
            reason="General Contact Form"
            fsID="mwkakkbr"
        />
    )
}

export function BecomeMemberForm() {
    return (
        <FormX
            inputs={[
                { title: "Full Name", type: "text" },
                { title: "Company Name", type: "text" },
                { title: "Company Email", type: "email" },
            ]}
            reason="Request for info: Becoming a CA Member"
            fsID="xzbybayy"
        />
    )
}

export function RequestProjectDetailsForm({ id }) {
    return (
        <FormX
            inputs={[
                { title: "Project ID", type: "text", value: id },
                { title: "Full Name", type: "text" },
                { title: "Company Name", type: "text" },
                { title: "Company Email", type: "email" },
            ]}
            reason="Request for info: Project Information"
            fsID="mqkwkyja"
        />
    )
}

export function ASKEGINewsletterForm() {
    return (
        <FormX
            inputs={[
                { title: "Full Name", type: "text" },
                { title: "Company Name", type: "text" },
                { title: "Company Email", type: "email" },
            ]}
            reason="New ASKEGI Subscriber!"
            fsID="mvodgpen"
        />
    )
}

export function MDForm({ formdata }) {
    return (
        <FormX
            inputs={formdata.inputs}
            reason={formdata.title}
            fsID={formdata.formspree}
            button={formdata.button}
            thank_you={formdata.thank_you}
            callback={() => {
                if (typeof window !== "undefined" && formdata.callback_file) {
                    window.open(formdata.callback_file.publicUrl, "_blank")
                }
            }}
        />
    )
}

export function EGIconnectRequestForm({ egi_number, title }) {
    return (
        <FormX
            inputs={[
                { title: "EGI Number", type: "text", value: egi_number, readOnly: true },
                { title: "Title", type: "text", value: title, readOnly: true},
                { title: "Full Name", type: "text" },
                { title: "Company Email Address", type: "text" },

            ]}
            reason="Request a document from EGIconnect"
            fsID="myyodjab"
        />
    );
}
