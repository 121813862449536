// import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Toast } from "react-bootstrap"
import { Helmet } from "react-helmet"
import ErrorBoundary from "../../errors/ErrorBoundary.js"

import Icon from "./Icon.jsx"

export function Component({ header, delay = 0, active, children }) {
    if ((!header, !active)) return null

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setVisible(true)
        }, delay)
    }, [setVisible])

    const toggleVisibility = () => setVisible(!visible)
    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`
                    .fa-box {
                        width: 20px;
                        height: 20px;
                        border-radius: 2px;
                        background: var(--red1);
                    }
                    `}
                </style>
            </Helmet>
            <Toast
                show={visible}
                onClose={toggleVisibility}
                className="bg-light"
                data-testid="toast"
                style={{ minWidth: "350px" }}
            >
                <Toast.Header>
                    <Icon />
                    <strong className="mr-auto" data-testid="header">
                        {header}
                    </strong>
                </Toast.Header>
                <Toast.Body className="p-2">{children}</Toast.Body>
            </Toast>
        </>
    )
}

export function Notification(props) {
    return (
        <ErrorBoundary
            fallback={{
                width: "300px",
                height: "150px",
                style: {
                    position: "fixed",
                    bottom: "2vh",
                    left: "2vh",
                    zIndex: "1",
                },
            }}
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
