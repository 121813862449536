import React from "react"
import { Tab } from "react-bootstrap"
import { removeLocalPath } from "../../cms/previews/tools/removeLocalPath"
import { Markdown } from "../tools/Markdown"

export function Pane({ data, e, index: i }) {
    const d = data[e]

    if (d?.media?.length) {
        var media = d.media[0]
        var file = media?.file?.publicURL
        file = file ? file : removeLocalPath(media?.file)
    }

    const body = d?.body ? d?.body : d?.html
    const elements = d?.elements

    if (!file && !body && !elements) return null

    if (data[e])
        return (
            <Tab.Pane
                data-testid="test-tab-pane"
                eventKey={"#link" + (i + 1)}
                key={i}
            >
                {media?.type == "image" ? <img src={file} alt={e} /> : null}
                {media?.type == "video" ? (
                    <video
                        controls
                        style={{
                            width: "100%",
                            height: "30vw",
                            maxHeight: "300px",
                            minHeight: "200px",
                            background: "black",
                        }}
                    >
                        <source src={file} type="video/mp4" />
                        <track kind="captions" srcLang="en" />
                    </video>
                ) : null}
                {body ? <Markdown>{body}</Markdown> : null}
                {elements ? (
                    <div className="d-flex flex-wrap">{elements}</div>
                ) : null}
            </Tab.Pane>
        )
}
