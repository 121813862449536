import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap"

export function Header({ menu }) {
    return (
        <Navbar
            className="fixed-top"
            bg="white"
            expand="lg"
            style={{ boxShadow: "0 0 4px #0000002b", minHeight: "83px" }}
        >
            <Container>
                <Link to="/" className="navbar-brand">
                    <img src="/media/EGI_logo_50th.png" width="260" alt="EGI Logo" />
                </Link>
                {menu ? (
                    <>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav
                                className="ml-auto mr-20"
                                data-testid="menu-navigation"
                            >
                                {menu.map(({ item }) => {
                                    if (item.sub_items) {
                                        return (
                                            <NavDropdown
                                                title={item.title}
                                                key={item.title}
                                                data-testid={`test-dropdown`}
                                            >
                                                {item.sub_items.map(
                                                    (
                                                        { hr, sub_item: sub },
                                                        i,
                                                    ) => {
                                                        if (hr)
                                                            return (
                                                                <NavDropdown.Divider
                                                                    key={i}
                                                                    data-testid={`test-hr`}
                                                                />
                                                            )
                                                        if (sub) {
                                                            if (sub.external) {
                                                                return (
                                                                    <a
                                                                        target="_blank"
                                                                        href={
                                                                            sub.href
                                                                        }
                                                                        className="dropdown-item"
                                                                        key={
                                                                            sub.title
                                                                        }
                                                                        noprefix="true"
                                                                        
                                                                        data-testid={`test-href-${sub.href}`}
                                                                    >
                                                                        {
                                                                            sub.title
                                                                        }
                                                                    </a>
                                                                )
                                                            }
                                                            return (
                                                                <Link
                                                                    to={
                                                                        sub.noprefix
                                                                            ? `/${sub.href}`
                                                                            : `/${item.prefix}/${sub.href}`
                                                                    }
                                                                    className="dropdown-item"
                                                                    data-testid={`test-to-${sub.href}`}
                                                                    key={
                                                                        sub.title
                                                                    }
                                                                >
                                                                    {sub.title}
                                                                </Link>
                                                            )
                                                        }
                                                    },
                                                )}
                                            </NavDropdown>
                                        )
                                    }
                                    return (
                                        <Link
                                            to={item.href}
                                            key={item.title}
                                            className="nav-link"
                                            data-testid={`test-to-${item.href}`}
                                        >
                                            {item.title}
                                        </Link>
                                    )
                                })}
                            </Nav>
                        </Navbar.Collapse>
                    </>
                ) : null}
            </Container>
        </Navbar>
    )
}
