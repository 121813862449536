import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"
import { Helmet } from "react-helmet"
import ErrorBoundary from "../errors/ErrorBoundary"
import { removeLocalPath } from "../../cms/previews/tools/removeLocalPath"

export function Component({ items = [], style, className }) {
    const [index, setIndex] = useState(0)

    if (!items.length) return null

    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`
                        .carousel-control-prev-icon, 
                        .carousel-control-next-icon, 
                        .carousel-indicators {
                            filter: invert(1);
                        }
                    `}
                </style>
            </Helmet>
            <Carousel
                activeIndex={index}
                onSelect={idx => {
                    setIndex(idx)
                }}
                className={className}
                style={style}
                data-testid="carousel"
            >
                {items.map((e, i) => {
                    let image = e.image
                    const gi = getImage(image)
                    return (
                        <Carousel.Item key={i}>
                            {!gi ? (
                                <img
                                    src={removeLocalPath(image)}
                                    alt="alternate"
                                />
                            ) : (
                                <GatsbyImage
                                    image={gi}
                                    alt={i + ""}
                                    style={{
                                        maxHeight: "300px",
                                        margin: "0 auto",
                                    }}
                                />
                            )}
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </>
    )
}

export function Slider(props) {
    return (
        <ErrorBoundary
            fallback={{
                width: "300px",
                height: "200px",
            }}
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
