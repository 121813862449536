import React from "react"

export function NotificationWrapper({ children }) {
    return (
        <div
            style={{
                position: "fixed",
                bottom: "2vh",
                left: "2vh",
                zIndex: "100",
            }}
        >
            {children}
        </div>
    )
}
