import { Button, Card } from "react-bootstrap"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export function PortfolioCard({
    title,
    image,
    button = "View PDF",
    href,
    style = { maxWidth: "350px" },
    className,
}) {
    if (!title || !href || !image) return null
    return (
        <Card className={className} style={style}>
            <Card.Header as="h5">{title}</Card.Header>
            <Card.Body>
                <GatsbyImage image={getImage(image)} alt={title} />
                <a href={href}>
                    <Button variant="egi">{button}</Button>
                </a>
            </Card.Body>
        </Card>
    )
}
