import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import ErrorBoundary from "../errors/ErrorBoundary"

export function Component({ title, subtitle, children, button }) {
    if (!title || !children || !button) return null

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
        <>
            <Button
                variant="egi"
                onClick={handleShow}
                data-testid="test-action-button"
            >
                {button}
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className="text-center w-100"
                        data-testid="test-title"
                    >
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-testid="test-body">
                    {subtitle ? (
                        <p className="text-center">{subtitle}</p>
                    ) : null}
                    {children}
                </Modal.Body>
            </Modal>
        </>
    )
}

export function ModalX(props) {
    return (
        <ErrorBoundary
            fallback={{
                width: "200px",
                height: "100px",
            }}
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
