import React from "react"
import { ListGroup, Tab, Col, Row } from "react-bootstrap"
import ErrorBoundary from "../errors/ErrorBoundary"
import { Pane } from "./Pane.jsx"

export function Component({ data, type }) {
    if (!data) return null

    const horizontal = type == "horizontal"

    const listItemStyle = {
        maxWidth: horizontal ? "fit-content" : "",
    }

    return (
        <Tab.Container
            defaultActiveKey="#link1"
            data-testid="test-tab-container"
        >
            <Row
                style={{
                    flexDirection: horizontal ? "column" : "row",
                    width: "100%",
                }}
            >
                <Col>
                    <ListGroup
                        data-testid="test-list-group"
                        className={horizontal ? "list-group-horizontal" : ""}
                    >
                        {Object.keys(data).map((e, i) => {
                            if (data[e]) {
                                if (data[e].title == "Download PDF") {
                                    return (
                                        <a
                                            href={data[e]?.publicURL}
                                            key={i}
                                            data-testid="test-list-group-anchor"
                                            className="list-group-item list-group-item-action text-center"
                                            style={listItemStyle}
                                        >
                                            Download PDF
                                        </a>
                                    )
                                }
                                return (
                                    <ListGroup.Item
                                        action
                                        href={"#link" + (i + 1)}
                                        key={i}
                                        style={listItemStyle}
                                        className="text-center"
                                        data-testid="test-list-group-item"
                                    >
                                        {data[e].title}
                                    </ListGroup.Item>
                                )
                            } else return null
                        })}
                    </ListGroup>
                </Col>
                <Col
                    sm={8}
                    style={{
                        minWidth: horizontal ? "100%" : "unset",
                    }}
                >
                    <Tab.Content
                        className="p-4"
                        style={{
                            minHeight: horizontal ? "200px" : "",
                        }}
                    >
                        {Object.keys(data).map((e, i) => (
                            <Pane data={data} e={e} key={i} index={i} />
                        ))}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

export function Tabs(props) {
    return (
        <ErrorBoundary
            fallback={{
                width: "100%",
                height: "400px",
            }}
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
