import React from "react"
import { Row } from "react-bootstrap"
import { PICard } from "../../components/elements"

export function ProjectFields({
    fm,
    fm: { price, status, availibility, contact } = {},
    members,
}) {
    if (!fm || !members?.length || !contact?.length) return null

    let memObj = {}
    members.forEach(({ node: { frontmatter: fm } }) => {
        memObj[fm.slug] = fm
    })

    return (
        <>
            {price ? (
                <>
                    <Row>
                        <b className="border-bottom pb-2 mb-3">Investment:</b>
                    </Row>
                    <Row className="mb-4">{price}</Row>
                </>
            ) : null}
            {status ? (
                <>
                    <Row>
                        <b className="border-bottom pb-2 mb-3">Status:</b>
                    </Row>
                    <Row className="mb-4">
                        {status + (availibility ? " | " + availibility : "")}
                    </Row>
                </>
            ) : null}
            <Row>
                <b className="border-bottom pb-2 mb-3">
                    Principal Investigator(s):
                </b>
            </Row>
            <Row>
                {contact.map((e, i) => (
                    <PICard key={i} member={memObj[e]} />
                ))}
            </Row>
        </>
    )
}
